import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd-mobile'
import { useLocation } from 'react-router'
import login_logo from 'static/images/distribute/login_logo.png'
import ic_paperless_warranty from 'static/images/distribute/ic_paperless_warranty.png'
import ic_warranty_extension from 'static/images/distribute/ic_warranty_extension.png'
import ic_information from 'static/images/distribute/ic_information.png'
import ic_hot_deals from 'static/images/distribute/ic_hot_deals.png'
import ic_dealer from 'static/images/distribute/ic_dealer.png'
import ic_genuine_product_confirmation from 'static/images/distribute/ic_genuine_product_confirmation.png'
import './Distribute.scss'
import service from 'service/pages/distribute'
import { getLocationFromBaiduApiByIpResponse } from 'interface/distribute/Distribute'
import { GetLocationIpInfoResponse, GetCountryListResponse } from 'interface/user/IpCountryToWebsite'
import { setLoading } from '../../redux/reducer/common'
import { useAppDispatch } from '../../hooks/reduxHooks'
import utils from '../../utils/utils'
import ToProWebsite from './ToProWebsite'

interface ItemCountry {
  name?: string
  country?: string
}

const Distribute = () => {
  const location = useLocation()
  let params = new URLSearchParams(location.search)
  const { t, i18n } = useTranslation()
  const [country, setCountry] = useState(params.get('country'))
  const [isToProWebsite, setToProWebsite] = useState(0)
  const [countryName, setCountryName] = useState('')
  const auto = params.get('auto')
  const app = params.get('app')
  const dispatch = useAppDispatch()

  useEffect(() => {
    // prettier-ignore
    (async () => {
      await _init().catch((err) => {
        console.log(err);
      }).finally(() => {
        dispatch(setLoading(false))
      })
    })()
    getCountryListData()
  }, [])

  const getCountryListData = async () => {
    const countryMastData: Array<string> = []
    const countryResut = (await service.getCountryList()) as GetCountryListResponse
    if (countryResut.data && countryResut.data.length > 0) {
      countryResut.data.map((item:ItemCountry) => {
        if(item.country) {
          countryMastData.push(item.country)
        }
      })
      getIpCountry(countryMastData)
    }
  }

  const getIpCountry = async (list: Array<string>) => {
    const ipCountryResult = (await service.getLocationIpInfo()) as GetLocationIpInfoResponse
    const countryRegion: object | any = ipCountryResult.countryRegion
    setCountryName(countryRegion?.name)
    if (list.includes(countryRegion?.isoCode)) {
      setToProWebsite(1)
    }else {
      setToProWebsite(2)
    }
  }

  const _init = async () => {
    dispatch(setLoading(true))
    if (!country) {
      const locationRes =
        (await service.getLocationFromBaiduApiByIp()) as getLocationFromBaiduApiByIpResponse
      const address = locationRes.address
      let newCountry = address ? address.split('|')[0] : null
      setCountry(newCountry)
    }
    dispatch(setLoading(false))
    if (auto == '1') {
      openAppLink('consumer')
    }
  }

  const jumpToVisitorTour = () => {
    let activeProfile = utils.getCurrentEnvConfig()
    let baseurl = activeProfile.globalBaseurl
    let url = `${activeProfile.surveyServer}/referral/visitorTourIndex?country=${country}&language=${i18n.language}&from=scanProduct&baseurl=${baseurl}`
    if (country === 'CN') {
      // baseurl = activeProfile.chinaBaseurl
      url = `weixin://dl/business/?appid=wx1b1f9ef6f3816626&path=pages/tabBar/home/home&query=from_channel%3DscanCode&env_version=${activeProfile.mpEnv}`
    } 
    
    window.location.href = url
    // window.location.href = `http://localhost:3000/referral/visitorTourIndex?country=${country}&language=${i18n.language}&from=scanProduct&baseurl=${baseurl}`
  }
  const openAppLink = (type: string): void => {
    dispatch(setLoading(true))
    const urlSchema: Record<string, { reg: RegExp; scheme: string }> = {
      xiaomi: {
        reg: /.*Android.*(MI|Mi|Redmi).*/,
        scheme: 'mimarket://',
      },
      huawei: {
        reg: /.*Android.*(Huawei|Honor).*/,
        scheme: 'appmarket://',
      },
      oppo: {
        reg: /.*Android.*(oppo|OPPO).*/,
        scheme: 'oppomarket://',
      },
      vivo: {
        reg: /.*Android.*(vivo|VIVO).*/,
        scheme: 'vivomarket://',
      },
    }
    let hrefAndroid: string = `https://play.google.com/store/apps/details?id=com.bosch.pt.beco.${type}`
    let hrefIos: string = `https://becoq2.bosch-ptqr.com/${type}universallink`
    let location: string = `${type}://`
    const u: string = navigator.userAgent
    const loadDateTime: number = Date.now()

    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
      // 安卓手机
      if (country === 'CN') {
        Object.keys(urlSchema).forEach((item: string) => {
          if (urlSchema[item].reg.test(u)) {
            hrefAndroid =
              type === 'consumer'
                ? `${urlSchema[item].scheme}details?id=com.bosch.pt.beco.consumer`
                : `${urlSchema[item].scheme}details?id=com.bosch.pt.beco.seller`
          }
        })
        if (
          hrefAndroid ===
          'https://play.google.com/store/apps/details?id=com.bosch.pt.beco.consumer'
        ) {
          hrefAndroid =
            'https://a.app.qq.com/o/simple.jsp?pkgname=com.bosch.pt.beco.consumer'
        }

        if (
          hrefAndroid ===
          'https://play.google.com/store/apps/details?id=com.bosch.pt.beco.seller'
        ) {
          hrefAndroid =
            'https://a.app.qq.com/o/simple.jsp?pkgname=com.bosch.pt.beco.seller'
        }
      }
      window.location.href = location
      {
        const timer: number = window.setTimeout(() => {
          const timeOutDateTime: number = Date.now()
          if (timeOutDateTime - loadDateTime > 500) {
            window.location.href = hrefAndroid
          }
        }, 1000)

        const visibilitychange = (): void => {
          clearTimeout(timer)
        }

        document.addEventListener('visibilitychange', visibilitychange, false)
        document.addEventListener(
          'webkitvisibilitychange',
          visibilitychange,
          false
        )

        window.addEventListener(
          'pagehide',
          (): void => {
            clearTimeout(timer)
          },
          false
        )
      }
    } else {
      dispatch(setLoading(false))
      // 苹果手机
      window.location.href = hrefIos
    }
  }

  if (isToProWebsite === 0) {
    return (
      <div className='loadingcanvas'></div>
    );
  } else if (isToProWebsite === 1) {
    return (
      <div className="distribute">
        <div className="login-description">
          <img src={login_logo} alt="logo" className="login-description-img" />
          <div className="login-description-title title1">
            {t('distribute.login-welcome1')}
          </div>
          <div className="login-description-title title2">
            {t('distribute.login-welcome2')}
          </div>
          <div className="login-description-title title3">
            {app === 'seller'
              ? t('distribute.global-title_tips_seller')
              : t('distribute.global-title_tips')}
          </div>
        </div>
        <ul className="register-list">
          <li>
            <img src={ic_paperless_warranty} alt="ic_paperless_warranty" />
            {t('distribute.global-benefits1')}
          </li>
          <li>
            <img src={ic_warranty_extension} alt="ic_warranty_extension" />
            {t('distribute.global-benefits2')}
          </li>
          <li>
            <img src={ic_information} alt="ic_information" />
            {t('distribute.global-benefits3')}
          </li>
          <li>
            <img src={ic_hot_deals} alt="ic_hot_deals" />
            {t('distribute.global-benefits4')}
          </li>
          <li>
            <img src={ic_dealer} alt="ic_dealer" />
            {t('distribute.global-benefits5')}
          </li>
          <li>
            <img
              src={ic_genuine_product_confirmation}
              alt="ic_genuine_product_confirmation"
            />
            {t('distribute.global-benefits6')}
          </li>
        </ul>
        {!app && (
          <div className="button">
            <Button
              className="download-button seller-button"
              onClick={() => openAppLink('seller')}
            >
              {t('distribute.global-seller')}
            </Button>
            <Button
              className="download-button user-button"
              onClick={jumpToVisitorTour}
            >
              {t('distribute.global-user')}
            </Button>
          </div>
        )}
        {!!app && (
          <div className="button">
            <Button
              className="download-button seller-button"
              onClick={() => openAppLink(app)}
            >
              {t('distribute.global-download')}
            </Button>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <ToProWebsite countryName={countryName}/>
    );
  }
}

export default Distribute
